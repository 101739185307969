<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="items"
    :items-per-page="size"
    :page="page"
    :server-items-length="count"
    :footer-props="{'items-per-page-options': [10, 20, 50, 100, 500]}"
    class="elevation-0 secondary-market-list"
    :item-class="(item) => !canInvest(item) ? 'uninvestable' : ''"
    @update:items-per-page="sizeChanged"
    @update:page="pageChanged"
  >
    <template #[`header.amount`]="{ header }">
      {{ header.text }}
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ $t('secondaryMarketPage.amountDescription') }}</span>
      </v-tooltip>
    </template>
    <!-- Commented out code is basically LoanList component parts that will potentially come into play once
    it's clear what exactly goes into a secondary market entry -->
    <!-- <template #[`header.available_to_invest`]="{ header }">
      {{ header.text }}
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ $t('loanList.availableForInvestmentDescription') }}</span>
      </v-tooltip>
    </template>
    <template #[`header.period`]="{ header }">
      {{ header.text }}
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ $t('loanList.loanTermsDescription') }}</span>
      </v-tooltip>
    </template>
    <template #[`header.investor_interest`]="{ header }">
      {{ header.text }}
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ $t('loanList.investorInterestPercentDescription') }}</span>
      </v-tooltip>
    </template> -->
    <!-- <template #[`item.country`]="{ item }">
      <BaseCountry :country="item.country "/>
    </template>
    <template #[`item.reference_number`]="{ item }">
      <div>{{ item.reference_number }}</div>
      <BaseLoanOriginatorChip :loan-originator="item.loan_originator"/>
    </template>
    <template #[`item.is_schedule_extended`]="{ item }">
      {{ item.is_schedule_extended? 'Yes': 'No' }}
    </template>
    <template #[`item.period`]="{ item }">
      {{ formattedPeriod(item.value_date, item.maturity_date) || $t('loanList.NA') }}
      ({{ formattedPeriod(new Date(), item.maturity_date) }})
    </template>

    <template #[`item.buyback_guarantee`]="{ item }">
      <v-icon class="mb-1" :title="item.buyback_guarantee ? $t('buyback.buybackGuaranteeYes') : $t('buyback.buybackGuaranteeNo')">{{ item.buyback_guarantee ? 'mdi-shield' : 'mdi-shield-off' }}</v-icon>
    </template> -->
    <template #[`item.id`]="{ item }">
      {{ item.id }}
    </template>
    <template #[`item.amount`]="{ item }">
      <BaseMoney :amount="item.amount"/>
    </template>
    <!-- <template #[`item.available_to_invest`]="{ item }">
      <BaseMoney :amount="item.available_to_invest"/>
    </template>
    <template #[`item.publishing_date`]="{ item }">
      <BaseDate :date="item.publishing_date" popup="left"/>
    </template>canInvest
    </template> -->
    <template #[`item.actions`]="{ item }">
      <v-menu
        v-if="showInvestButton(item)"
        v-model="menu[item.id]"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template #activator="{ on, attrs }">
          <v-btn
            small
            dark
            :disabled="!canInvest(item)"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('secondaryMarketPage.invest') }}
          </v-btn>
        </template>
        <StartInvesting :invest-target="{secondaryMarketEntry:item}" @close="menu[item.id] = false" @success="investmentChanged"/>
      </v-menu>
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <v-fab-transition>
            <v-icon
              v-if="!showInvestButton(item)"
              color="success"
              v-bind="attrs"
              v-on="on"
            >
              mdi-briefcase-check
            </v-icon>
          </v-fab-transition>
        </template>
        <span v-text="$t('secondaryMarketPage.youHaveAlreadyInvested')"/>
      </v-tooltip>
    </template>
    <!-- <template #expanded-item="{ headers: expandedHeaders, item }">
      <td class="px-0" :colspan="expandedHeaders.length">
        <v-expand-transition>
          <LoanDetail v-show="showing[item.id]" :loan="item"/>
        </v-expand-transition>
      </td>
    </template> -->
  </v-data-table>
</template>

<script>
import Vue from 'vue'
import StartInvesting from "../components/StartInvesting"
// import LoanDetail from "../components/LoanDetail.vue"
// import {addHours, formatDuration, intervalToDuration} from "date-fns"
// import { de, enGB, es, et, fr, ru } from "date-fns/locale"

export default Vue.extend({
  name: "SecondaryMarketEntryList",
  components: {
    StartInvesting,
    // LoanDetail,
  },
  props: {
    count: { type: Number, required: true },
    page: { type: Number, required: true },
    size: { type: Number, required: true },
    items: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },
  data() {
    return {
      expanded: [],
      menu: {},
      showing: {},
    }
  },
  computed: {
    headers() {
      return [
        { text: '',                                         class: 'text-no-wrap', cellClass: 'text-no-wrap', align: 'start', sortable: false, value: 'id' },
        { text: this.$t('secondaryMarketPage.amount'),      class: 'text-no-wrap', cellClass: 'text-no-wrap', align: 'start', sortable: false, value: 'amount' },
        // { text: '',                                                                                                           sortable: false, value: 'data-table-expand' },
        // { text: '',                                                                                           align: 'start', sortable: false, value: 'country' },
        // { text: this.$t('loanList.loanId'),                 class: 'text-no-wrap', cellClass: 'text-no-wrap', align: 'start', sortable: false, value: 'reference_number' },
        // { text: this.$t('loanList.loanListedDate'),         class: 'text-no-wrap', cellClass: 'text-no-wrap', align: 'start', sortable: false, value: 'publishing_date' },
        // { text: this.$t('loanList.loanAmount'),             class: 'text-no-wrap', cellClass: 'text-no-wrap', align: 'start', sortable: false, value: 'amount' },
        // { text: this.$t('loanList.interestRatePercent'),    class: 'text-no-wrap', cellClass: 'text-no-wrap', align: 'start', sortable: false, value: 'investor_interest' },
        // { text: this.$t('loanList.loanTerms'),              class: 'text-no-wrap', cellClass: 'text-no-wrap', align: 'start', sortable: false, value: 'period' },
        // { text: this.$t('loanList.availableForInvestment'), class: 'text-no-wrap', cellClass: 'text-no-wrap', align: 'start', sortable: false, value: 'available_to_invest' },
        { text: '',                                                                                           align: 'center', sortable: false, value: 'actions',             width: "calc(7rem + 20px)" },
      ]
    },
    // investmentAccount() {
    //   return this.$store.state.account
    // },
  },
  methods: {
    investmentChanged() { this.$emit('investment') },
    pageChanged(value) { this.$emit('update:page', value) },
    sizeChanged(value) { this.$emit('update:size', value) },
    // formattedPeriod(dateFrom, dateTo) {
    //   // This is needed to compensate for 1 hour difference between local environment and testing.
    //   // The difference is caused by the fact that on server we don't have Daylight saving time enabled.
    //   dateTo = addHours(new Date(dateTo), 2)
    //   let timeBetweenDates = intervalToDuration({ start: new Date(dateFrom), end: new Date(dateTo) })
    //   timeBetweenDates.months += timeBetweenDates.years * 12
    //   timeBetweenDates.years = 0
    //   let matches = formatDuration(
    //     timeBetweenDates,
    //     {
    //       format: ['months', 'days'],
    //       zero: true,
    //       locale: { 'et': et, 'ru': ru, 'en': enGB, 'de': de, 'fr': fr, 'es': es }[this.$i18n.locale],
    //     })
    //   return matches.replace('üks', '1') // for some reason "1" in et locale is translated to "üks", this is a hack to override this behaviour
    //     .match(/\d+\s./g).map(item => item.replace(/\s/, '')).join(' ')
    // },
    // expandChange(row) {
    //   // Vuetify does not provide expand transitions. We have to
    //   // implement our own. See https://github.com/vuetifyjs/vuetify/issues/8197
    //   Vue.nextTick().then(() => {
    //     this.$set(this.showing, row.item.id, row.value)
    //   })
    // },
    showInvestButton(/*loan*/) {
      // TODO: proper logic
      // Note that primary market has inverse logic for some reason (showInvestButton == true HIDES the button)
      return true
    },
    canInvest(/*item*/) {
      // TODO: proper logic
      // Loans use the following, however secondary market is likely to have
      // many low-amount options, so not sure if this applies
      // return item.available_to_invest >= 10
      return true
    },
  },
})
</script>
<style>
.secondary-market-list .secondary-market .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded.v-data-table__expanded__row {
  background: #eeeeee;
}

.secondary-market-list .v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row{
  display: table-row;
  width: 100%;
}

.secondary-market-list tr.uninvestable {
  color: gray;
}
</style>
