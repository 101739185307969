var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 secondary-market-list",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"items-per-page":_vm.size,"page":_vm.page,"server-items-length":_vm.count,"footer-props":{'items-per-page-options': [10, 20, 50, 100, 500]},"item-class":function (item) { return !_vm.canInvest(item) ? 'uninvestable' : ''; }},on:{"update:items-per-page":_vm.sizeChanged,"update:page":_vm.pageChanged},scopedSlots:_vm._u([{key:"header.amount",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"slot":"activator","dense":"","size":"12","color":"primary","dark":""},slot:"activator"},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('secondaryMarketPage.amountDescription')))])])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('BaseMoney',{attrs:{"amount":item.amount}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showInvestButton(item))?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","disabled":!_vm.canInvest(item)}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('secondaryMarketPage.invest'))+" ")])]}}],null,true),model:{value:(_vm.menu[item.id]),callback:function ($$v) {_vm.$set(_vm.menu, item.id, $$v)},expression:"menu[item.id]"}},[_c('StartInvesting',{attrs:{"invest-target":{secondaryMarketEntry:item}},on:{"close":function($event){_vm.menu[item.id] = false},"success":_vm.investmentChanged}})],1):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-fab-transition',[(!_vm.showInvestButton(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-briefcase-check ")]):_vm._e()],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('secondaryMarketPage.youHaveAlreadyInvested'))}})])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }